<template>
  <div v-if="result">
    <div class="container-top">
      <div class="pc text-center padding-top-56">
        <div class="h5" v-if="isFail">입력하신 카드정보가 잘못되었습니다. 다시 입력하시기 바랍니다.</div>
        <div class="h5" v-else>카드정보가 정상적으로 등록되었습니다.</div>
        <div class="h6 margin-top-16">오류내용: {{ result.resultMsg }}</div>
      </div>
      <div class="mobile text-center" style="padding:56px 16px">
        <div class="h7" v-if="isFail">입력하신 카드정보가 잘못되었습니다. <br> 다시 입력하시기 바랍니다.</div>
        <div class="h7" v-else>카드정보가 정상적으로 등록되었습니다.</div>
        <div class="h8 margin-top-8">오류내용: {{ result.resultMsg }}</div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "RegBillSuccess",
    created() {
      this.result = JSON.parse(this.$route.query.result);
      let resultCode = this.result.resultCode;
      if (resultCode !== '00' && resultCode !== '0000') {
        this.toast('입력하신 카드정보가 잘못되었습니다. 다시 입력하시기 바랍니다.');
        return;
      }
    },
    data() {
      return {
        result: undefined
      }
    },
    computed: {
      isFail() {
        return this.result && this.result.resultCode !== '00' && this.result.resultCode !== '0000'
      }
    },
    methods: {}
  }
</script>
<style lang="stylus" scoped>
</style>